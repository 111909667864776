import React, { useCallback, useState } from "react";

import { Link } from "gatsby-plugin-intl";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ArrowBackIcon from "../images/icons/arrow_backward.inline.svg";

function NotFound() {
  const [happy, setHappy] = useState(false);
  const changeMood = useCallback(() => setHappy((happy) => !happy), []);
  return (
    <Layout hideContactForm>
      <SEO title="Not Found Page | Younics" />
      <div className="container pt-32 md:pt-52 pb-24 lg:pb-32 xl:pb-40">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-6xl lg:text-7xl text-center text-red-bright leading-none pb-8">
            404
          </h1>
          <h2 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center leading-none pb-8">
            Oops... page not found.
          </h2>
          <p className="text-gray-purple text-center max-w-lg mx-auto pb-20">
            Sorry{" "}
            {happy ? (
              <span role="img" aria-label="Happy emoji" className="text-2xl">
                😀
              </span>
            ) : (
              <span role="img" aria-label="Pensive emoji" className="text-2xl">
                😔
              </span>
            )}{" "}
            we couldn’t find this page you were looking for. But{" "}
            <button
              onClick={changeMood}
              aria-label="Change Mood"
              className="text-purple hover:text-white focus:outline-none"
            >
              don't worry
            </button>
            , you can find other pages from our{" "}
            <Link
              to="/"
              className="text-purple hover:text-white focus:outline-none"
            >
              homepage
            </Link>
            .
            <br />
          </p>
          <div className="flex justify-center">
            <Link
              to="/"
              className="btn inline-flex items-center group bg-gradient-to-r from-purple to-blue"
            >
              <ArrowBackIcon className="fill-current transition duration-500 ease-in-out transform group-hover:-translate-x-2 uppercase" />
              <span className="pl-2">Back to Home</span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default NotFound;
